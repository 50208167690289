<template>
  <div :class="classes">
    <div v-if="!errorObject.success">
      <div
        class="text-heading-4 mb-6 text-left font-medium leading-7"
        :class="award && 'text-primary !mb-3 text-2xl font-bold uppercase'"
        data-test="contact_us_form_h1"
      >
        {{ title }}
      </div>

      <div
        v-if="description"
        class="text-3 text-blue-gray-700 mb-6 leading-[19px]"
        :class="award && 'text-center'"
      >
        Оставьте заявку, станьте нашим партнером и развивайте бизнес вместе с
        нами!
      </div>

      <form class="coop-form" @submit="handleFeedback">
        <div class="mb-6">
          <label
            class="text-3 mb-3 block leading-4 text-gray-700"
            data-test="contact_us_form_fio_h"
          >
            ФИО *</label
          >
          <input
            :class="[
              'input input_outlined',
              !!errorObject.full_name ? 'ring-danger' : '',
            ]"
            type="text"
            data-test="contact_us_form_fio_field"
            :placeholder="placeHolder.name"
            @input="
              (e: InputEvent) =>
                (callbackForm.fullName = (e.target as HTMLInputElement).value)
            "
          />
          <div v-if="!!errorObject.full_name" class="text-danger">
            {{ errorObject.full_name[0] }}
          </div>
        </div>

        <div class="mb-6">
          <label
            class="text-3 mb-3 block leading-4 text-gray-700"
            data-test="contact_us_form_email_h"
          >
            Email *
          </label>
          <input
            v-maska:[mailMaskOptions]
            :class="[
              'input input_outlined',
              !!errorObject.email ? 'ring-danger' : '',
            ]"
            data-test="contact_us_form_email_field"
            type="email"
            :pattern="mailPattern"
            :placeholder="placeHolder.mail"
            @input="
              (e: InputEvent) =>
                (callbackForm.email = (e.target as HTMLInputElement).value)
            "
          />
          <div v-if="!!errorObject.email" class="text-danger">
            {{ errorObject.email[0] }}
          </div>
        </div>

        <div class="mb-6">
          <label
            class="text-3 mb-3 block leading-4 text-gray-700"
            data-test="contact_us_form_phone_h"
          >
            Номер телефона
          </label>
          <input
            v-maska:[phoneMaskOptions]
            :class="[
              'input input_outlined',
              !!errorObject.phone ? 'ring-danger' : '',
            ]"
            data-test="contact_us_form_phone_field"
            type="text"
            :placeholder="placeHolder.tel"
            :pattern="phonePattern"
            @input="
              (e: InputEvent) =>
                (callbackForm.phone = (e.target as HTMLInputElement).value)
            "
          />
          <div v-if="!!errorObject.phone" class="text-danger">
            {{ errorObject.phone[0] }}
          </div>
        </div>

        <div v-if="isRegion" class="mb-6">
          <label
            class="text-3 mb-3 block leading-4 text-gray-700"
            data-test="contact_us_form_region_h"
          >
            Регион (Город)
          </label>
          <input
            :class="[
              'input input_outlined',
              !!errorObject.region ? 'ring-danger' : '',
            ]"
            data-test="contact_us_form_region_field"
            type="text"
            :placeholder="placeHolder.city"
            @input="
              (e: InputEvent) =>
                (callbackForm.region = (e.target as HTMLInputElement).value)
            "
          />
          <div v-if="!!errorObject.region" class="text-danger">
            {{ errorObject.region[0] }}
          </div>
        </div>
        <div v-if="isVet" class="mb-6">
          <label class="text-3 mb-3 block leading-4 text-gray-700">
            Название ветеринарной клиники
          </label>
          <input
            :class="[
              'input input_outlined',
              !!errorObject.clinic ? 'ring-danger' : '',
            ]"
            type="text"
            :placeholder="placeHolder.clinic"
            @input="
              (e: InputEvent) =>
                (callbackForm.clinic = (e.target as HTMLInputElement).value)
            "
          />
          <div v-if="!!errorObject.clinic" class="text-danger">
            {{ errorObject.clinic[0] }}
          </div>
        </div>
        <div class="mb-6">
          <label
            class="text-3 mb-3 block leading-4 text-gray-700"
            data-test="contact_us_form_message_h"
          >
            Текст сообщения *
          </label>
          <textarea
            rows="3"
            :class="[
              'input input_outlined h-[78px] resize-none',
              !!errorObject.message ? 'ring-danger' : '',
            ]"
            data-test="contact_us_form_message_field"
            :placeholder="placeHolder.text"
            @input="
              (e: InputEvent) =>
                (callbackForm.message = (e.target as HTMLTextAreaElement).value)
            "
          />
          <div v-if="!!errorObject.message" class="text-danger">
            {{ errorObject.message[0] }}
          </div>
        </div>

        <Btn
          type="submit"
          lg
          primary
          class="style_app w-full text-lg"
          data-test="contact_us_form_send_button"
        >
          Отправить
        </Btn>
        <div class="flex max-w-full items-center space-x-4">
          <input
            id="privacy-confirm"
            v-model="checkbox"
            class="accent-primary"
            required
            data-test="contact_us_form_agreement_checkbox"
            type="checkbox"
          />
          <label
            class="mt-2 text-left text-sm"
            for="privacy-confirm"
            data-test="contact_us_form_agreement_text"
          >
            Подтверждаю, что я ознакомлен и согласен с условиями
            <a
              class="text-primary hover:text-primary-hover"
              href="/rules/policy"
              target="_blank"
              >политики конфиденциальности</a
            >
            и
            <a
              class="text-primary hover:text-primary-hover"
              href="/terms.pdf"
              target="_blank"
              >обработки персональных данных</a
            >
          </label>
        </div>
      </form>
    </div>
    <div v-else class="flex h-full flex-col items-center justify-center">
      <div
        class="text-heading-4 mb-6 text-center font-medium leading-7 md:mb-8 md:text-center"
      >
        <Icon
          name="check-circle-solid"
          class="m-auto h-16 w-16 text-green-900"
        />
        <p class="pb-[32px] pt-[19px]">
          Ваше обращение принято! <br />
          Мы рассмотрим его <br />
          в ближайшее время!
        </p>
      </div>
      <Btn
        primary
        class="h-auto w-full p-[14px] text-[18px] leading-6"
        @click="clearStateForm"
      >
        Продолжить
      </Btn>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { FeedbackRequest } from '@/openapi_fetch'

const { FeedbackApi } = useOpenApi()
const checkbox = ref(false)

interface ErrorObj {
  success: boolean
  full_name?: string[]
  email?: string[]
  phone?: string[]
  region?: string[]
  clinic?: string[]
  message?: string[]
}
interface Placeholders {
  name?: string
  mail?: string
  tel?: string
  city?: string
  clinic?: string
  text?: string
}
interface Props {
  title?: string
  formName?: string
  classes?: string
  isModal?: boolean
  description?: string
  isRegion?: boolean
  isVet?: boolean
  award?: boolean
  placeHolder?: Placeholders
}

const props = withDefaults(defineProps<Props>(), {
  title: 'Написать нам',
  formName: 'contacts',
  classes:
    'w-full lg:w-[477px] md:w-[39%] md:min-w-[39%] mt-11 md:mt-0 lg:min-w-[477px] h-auto md:ml-7 rounded-[13px] shadow p-6',
  isModal: false,
  description: '',
  isRegion: true,
  isVet: false,
  award: false,
  placeHolder: () => ({
    name: 'Ваше ФИО',
    mail: 'Ваш e-mail',
    tel: '+7 (___) ___-__-__',
    city: 'Ваш город',
    clinic: 'Название ветеринарной клиники',
    text: 'Напишите ваш вопрос',
  }),
})

const { phoneMaskOptions, mailMaskOptions, phonePattern, mailPattern } =
  useMasks()

const callbackFormState: FeedbackRequest = {
  formName: props.formName,
  fullName: '',
  email: '',
  phone: '',
  region: '',
  clinic: '',
  message: '',
}

const callbackForm = reactive({
  ...callbackFormState,
})

const emit = defineEmits(['close'])

const errorObject = ref<ErrorObj>({
  success: false,
})

async function handleFeedback(e: Event) {
  e.preventDefault()
  if (!checkbox) {
    return
  }
  try {
    await FeedbackApi.feedbackFeedbackCreate({
      feedbackRequest: callbackForm,
    })
    errorObject.value = { success: true }
  } catch (error: any) {
    // TODO надо чтобы поле message тоже приходило в поле detail_data
    // @ts-expect-error
    errorObject.value = {}
    if (error.response?._data?.detail_data) {
      errorObject.value = Object.assign(errorObject.value, {
        ...error.response._data.detail_data,
      })
    }
    if (error.response?._data?.message) {
      errorObject.value = Object.assign(errorObject.value, {
        message: [error.response._data.message],
      })
    }
    throw new Error(error)
  }
}

function clearForm() {
  errorObject.value = { success: false }
  Object.assign(callbackForm, callbackFormState)
}

function clearStateForm() {
  if (props.isModal) {
    emit('close')
    setTimeout(clearForm, 250)
  } else {
    clearForm()
  }
}
</script>
