/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeliverPoints
 */
export interface DeliverPoints {
    /**
     * 
     * @type {number}
     * @memberof DeliverPoints
     */
    readonly id: number;
    /**
     * 
     * @type {boolean}
     * @memberof DeliverPoints
     */
    readonly isBlocked: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DeliverPoints
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliverPoints
     */
    typePints?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliverPoints
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliverPoints
     */
    regionCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliverPoints
     */
    region?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeliverPoints
     */
    cityCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeliverPoints
     */
    longitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeliverPoints
     */
    latitude?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DeliverPoints
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliverPoints
     */
    addressFull?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliverPoints
     */
    addressComment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliverPoints
     */
    workTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliverPoints
     */
    phones?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliverPoints
     */
    email?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeliverPoints
     */
    weightMax?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeliverPoints
     */
    widthMax?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeliverPoints
     */
    heightMax?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeliverPoints
     */
    depthMax?: number | null;
}

/**
 * Check if a given object implements the DeliverPoints interface.
 */
export function instanceOfDeliverPoints(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "isBlocked" in value;

    return isInstance;
}

export function DeliverPointsFromJSON(json: any): DeliverPoints {
    return DeliverPointsFromJSONTyped(json, false);
}

export function DeliverPointsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliverPoints {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'isBlocked': json['is_blocked'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'typePints': !exists(json, 'type_pints') ? undefined : json['type_pints'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'regionCode': !exists(json, 'region_code') ? undefined : json['region_code'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'cityCode': !exists(json, 'city_code') ? undefined : json['city_code'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'addressFull': !exists(json, 'address_full') ? undefined : json['address_full'],
        'addressComment': !exists(json, 'address_comment') ? undefined : json['address_comment'],
        'workTime': !exists(json, 'work_time') ? undefined : json['work_time'],
        'phones': !exists(json, 'phones') ? undefined : json['phones'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'weightMax': !exists(json, 'weight_max') ? undefined : json['weight_max'],
        'widthMax': !exists(json, 'width_max') ? undefined : json['width_max'],
        'heightMax': !exists(json, 'height_max') ? undefined : json['height_max'],
        'depthMax': !exists(json, 'depth_max') ? undefined : json['depth_max'],
    };
}

export function DeliverPointsToJSON(value?: DeliverPoints | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'type_pints': value.typePints,
        'name': value.name,
        'region_code': value.regionCode,
        'region': value.region,
        'city_code': value.cityCode,
        'longitude': value.longitude,
        'latitude': value.latitude,
        'address': value.address,
        'address_full': value.addressFull,
        'address_comment': value.addressComment,
        'work_time': value.workTime,
        'phones': value.phones,
        'email': value.email,
        'weight_max': value.weightMax,
        'width_max': value.widthMax,
        'height_max': value.heightMax,
        'depth_max': value.depthMax,
    };
}

