<template>
  <div
    v-if="props.lite"
    class="container hidden items-center justify-center py-5 md:flex"
  >
    <Btn
      secondary
      to="/catalog/"
      class="flex items-center gap-2 bg-[#EFF3F8] text-[#637692]"
    >
      <Icon name="arrow-left" class="h-[12px] w-[12px]" />
      <span class="">В&nbsp;каталог</span>
    </Btn>
    <NuxtLink
      id="appLogoSidebar"
      to="/"
      class="flex w-full justify-center pr-[100px]"
      @click="closeMenu"
    >
      <slot name="logo" />
    </NuxtLink>
  </div>

  <div v-else>
    <BannerCross />

    <AppHeaderAddressMobile
      v-if="!isNavbarHidden && !hideAddress && !kranch"
      :address-text="addressText"
      :address-full-text="addressFullText"
      @show-dialog="deliveryAddressDialog = true"
    />

    <header class="container relative z-[20]">
      <div
        v-if="!isNavbarHidden && !fiory && !b4p"
        class="region flex items-center py-2 lg:h-12 lg:space-x-3 lg:py-0"
        :class="mnyams && 'hidden border-[#EFEFEF] md:flex md:border-b-[1px]'"
      >
        <AppHeaderNavMenu
          :hidden-address="!(kranch || isb)"
          :hidden-address-xl="fedor"
          :address-text="addressText"
          :address-full-text="addressFullText"
          :items="items"
          :item-class="isb ? 'text-primary-500' : ''"
          @show-dialog="deliveryAddressDialog = true"
          @go-to-auth-before="goToAuthPage"
        />

        <AppHeaderContacts
          :phone-to="phoneTo"
          :phone-class="mnyams || isb ? '!font-normal !text-black' : ''"
          :social-items="socialItems"
          :social-class="isb ? 'lg:hidden' : ''"
          :social-item-class="mnyams ? '!text-primary !bg-base-primary' : ''"
        />
      </div>

      <AppHeaderNavDrawer
        v-model="isOpenedMenu"
        :address-text="addressText"
        :address-full-text="addressFullText"
        :phone-to="phoneTo"
        :menu-class="!kranch ? 'max-w-[295px]' : ''"
        :nav-class="kranch ? 'mx-3' : ''"
        :nav-item-class="kranch ? 'text-black' : ''"
        :items="items"
        @close="closeMenu"
        @go-to-auth-before="goToAuthPage"
      >
        <template v-if="kranch" #logo-top>
          <div
            class="m-2 flex justify-between rounded-[20px] bg-white p-4 shadow"
          >
            <NuxtLink
              id="appLogoKranch"
              to="/"
              :class="['lg:mt-2 lg:block']"
              aria-label="Главная страница"
              @click="closeMenu"
            >
              <slot name="logo" />
            </NuxtLink>

            <Btn
              secondary
              class="h-[52px] w-[52px] shrink-0 rounded-[20px] bg-[#F8F8F8] text-gray-400"
              aria-label="Меню"
              @click="isOpenedMenu = !isOpenedMenu"
            >
              <Icon
                v-if="isOpenedMenu"
                name="close"
                class="text-primary h-5 w-5"
              />

              <Icon v-else name="drag-n-drop" class="h-4 w-4" />
            </Btn>
          </div>
        </template>

        <template v-if="!kranch" #logo-bottom>
          <div class="px-10 pb-12 pt-6 text-center">
            <SocialLinks :items="socialItems" class="mx-auto mb-6" />
            <NuxtLink
              id="appLogoSidebar"
              to="/"
              class="flex w-full justify-center"
              @click="closeMenu"
            >
              <slot name="logo" />
            </NuxtLink>
          </div>
        </template>

        <template v-if="kranch" #address>
          <li>
            <section class="w-full border-b border-b-gray-100 py-2.5 lg:hidden">
              <button
                class="text-primary flex w-full items-center px-[14px] text-xs transition"
                @click="deliveryAddressDialog = true"
              >
                <Icon
                  name="marker-pin"
                  class="text-primary mr-2 h-[18px] w-[18px] shrink-0"
                />

                <span class="truncate">
                  <span
                    v-if="addressText"
                    class="font-semibold"
                    :title="addressFullText"
                    >{{ addressText }}</span
                  >

                  <span v-else>Укажите адрес доставки</span>
                </span>

                <Icon
                  name="expand-down"
                  class="ml-1 h-[14px] w-[14px] shrink-0"
                />
              </button>
            </section>
          </li>
        </template>

        <template v-if="!kranch" #phone-nav>
          <div class="pt-4.5 p-3.5">
            <a
              :href="`tel:${phoneTo}`"
              class="text-2 text-primary font-medium leading-[21px]"
            >
              {{ phoneTo }}
            </a>

            <p class="text-4 mt-1 leading-4">Ежедневно 9:00-21:00 Мск</p>
          </div>
        </template>

        <template v-if="kranch" #phone-bottom>
          <div
            class="m-3 flex items-center justify-between rounded-[20px] bg-[#F6F6F6] p-4"
          >
            <NuxtLink
              class="flex items-center gap-x-2.5"
              :to="`tel:${phoneTo}`"
            >
              <Icon name="phone" class="text-primary h-4 w-4" />

              <div>
                <a
                  :href="`tel:${phoneTo}`"
                  class="text-4 text-primary font-normal hover:underline"
                >
                  {{ phoneTo }}
                </a>

                <div class="leading-base text-xs">Ежедневно 9:00-21:00 Мск</div>
              </div>
            </NuxtLink>

            <a href="https://vk.com/mrkranch" target="_blank">
              <Icon name="vk-alt" class="text-primary h-8 w-8" />
            </a>
          </div>
        </template>
      </AppHeaderNavDrawer>

      <div class="pt-2 lg:-mt-2 lg:py-6">
        <Transition name="navbar-on-scroll">
          <div
            v-show="!isNavbarHidden || mnyams"
            v-if="!isDesktop"
            class="mb-2 flex items-center justify-between gap-x-2.5"
          >
            <div v-if="mnyams" class="flex items-center gap-x-2">
              <NuxtLink to="/catalog/">
                <Btn
                  secondary
                  class="mnyams-navicon !bg-primary h-[52px] w-[52px] shrink-0 !text-white lg:hidden"
                  aria-label="phone"
                >
                  <Icon name="catalog" class="h-5 w-5" />
                </Btn>
              </NuxtLink>

              <AppHeaderSearch v-model="searchActive" :kranch="kranch" />
            </div>

            <NuxtLink id="appLogoMobile" to="/">
              <slot
                name="logo"
                :class="{ 'absolute !-bottom-5  sm:!-bottom-7': mnyams }"
              />
            </NuxtLink>

            <div v-if="!b4p" class="flex items-center gap-x-2">
              <AppHeaderSearch
                v-if="!kranch && !mnyams && !fiory"
                v-model="searchActive"
                :kranch="kranch"
              />

              <NuxtLink :to="`tel:${phoneTo}`">
                <Btn
                  secondary
                  class="text-primary bg-btn-secondary mnyams-navicon h-[52px] w-[52px] shrink-0 lg:hidden"
                  aria-label="phone"
                >
                  <Icon name="phone" class="h-4 w-4" />
                </Btn>
              </NuxtLink>

              <Btn
                secondary
                class="text-primary bg-btn-secondary mnyams-navicon h-[52px] w-[52px] shrink-0 lg:hidden"
                aria-label="Меню"
                @click="isOpenedMenu = !isOpenedMenu"
              >
                <Icon v-if="isOpenedMenu" name="close" class="h-5 w-5" />
                <Icon v-else name="drag-n-drop" class="h-4 w-4" />
              </Btn>
            </div>

            <div v-else-if="!mnyams" class="flex items-center gap-x-2">
              <AppHeaderSearch
                v-if="!kranch && !mnyams && !fiory"
                v-model="searchActive"
                :kranch="kranch"
              />

              <slot name="mobnav" />
            </div>
          </div>
        </Transition>

        <div
          class="relative flex h-[52px] justify-between gap-3 overflow-hidden lg:gap-2 lg:overflow-visible"
          :class="!kranch && 'hidden lg:flex'"
        >
          <div v-if="!mnyams" class="flex shrink-0 items-center gap-2">
            <NuxtLink
              v-if="!mnyams"
              id="appLogoDesk"
              to="/"
              :class="[
                'hidden shrink-0 lg:mt-2 lg:block',
                kranch ? 'lg:mr-6 xl:mr-[66px]' : 'lg:mr-6',
              ]"
              aria-label="Главная страница"
            >
              <slot name="logo" />
            </NuxtLink>

            <div v-if="b4p" class="flex items-center">
              <Popper
                v-if="!hideAddress"
                hover
                content="Адрес доставки"
                class="!hidden lg:!inline-block"
              >
                <Btn
                  class="hidden w-[139px] space-x-2 text-left text-white lg:flex"
                  secondary
                  aria-label="Адрес доставки"
                  @click="deliveryAddressDialog = true"
                >
                  <Icon name="marker-pin" class="h-5 w-5 shrink-0" />
                  <div
                    class="leading-base line-clamp-2 whitespace-normal text-xs"
                  >
                    <span v-if="addressText" :title="addressFullText">
                      {{ addressText }}
                    </span>
                    <span v-else> Укажите адрес доставки </span>
                  </div>
                </Btn>
              </Popper>
            </div>
          </div>

          <Btn
            v-if="!fedor && !kranch"
            secondary
            class="text-primary bg-btn-secondary h-[52px] w-[52px] shrink-0 lg:hidden"
            :class="kranch && 'bg-primary rounded-2xl text-white'"
            aria-label="Меню"
            @click="isOpenedMenu = !isOpenedMenu"
          >
            <Icon v-if="isOpenedMenu" name="close" class="h-5 w-5" />
            <Icon v-else name="catalog" class="h-5 w-5" />
          </Btn>

          <div
            v-if="!b4p"
            class="flex w-full gap-2 md:gap-0"
            :class="[
              mnyams &&
                '!m-0 min-h-[62px] max-w-[427px] items-center rounded-[45px]  bg-gray-100 p-[5px]',
              isb &&
                '!m-0 !mt-2 h-[52px] items-center rounded-[15px] bg-gray-50 p-[5px]',
              fiory && 'items-center gap-4',
            ]"
          >
            <Btn
              v-if="!isb"
              id="appHeaderCatalogButton"
              to="/catalog/"
              primary
              data-test="header_catalog"
              class="mr-1 hidden h-[52px] space-x-1.5 px-5 text-sm font-semibold lg:mt-2 lg:flex"
              :class="[
                fedor && '!flex',
                mnyams && '!mt-0 rounded-[42px]',
                (kranch || fiory) &&
                  'bg-primary !flex !px-4 !text-base !font-normal text-white',
                fiory && 'bg-accent',
              ]"
            >
              <Icon v-if="!kranch" name="catalog" class="h-[22px] w-[22px]" />
              <Icon
                v-else-if="!fiory"
                name="catalog-dots"
                class="h-[22px] w-[22px]"
              />
              <span class="hidden md:block">Каталог</span>
            </Btn>

            <Btn
              v-else
              to="/catalog/"
              data-test="header_catalog"
              class="text-primary-500 mr-1 hidden items-center space-x-1.5 pl-5 text-sm font-semibold lg:flex"
            >
              <Icon name="catalog-alt" class="h-[22px] w-[22px]" />
              <span class="hidden md:block">Каталог</span>
            </Btn>

            <NuxtLink
              v-if="fiory"
              data-test="header_contact_number"
              class="text-xl font-bold text-white"
              :to="`tel:${phoneTo}`"
            >
              {{ phoneTo }}
            </NuxtLink>

            <div
              v-if="isb"
              class="ml-2 h-[60%] w-[1px] bg-gray-300 opacity-50"
            />

            <div
              v-if="!fiory"
              class="flex w-full lg:mt-2"
              :class="mnyams && '!mt-0'"
            >
              <AppHeaderSearch
                v-model="searchActive"
                :mnyams="mnyams"
                :isb="isb"
                :kranch="kranch"
                placeholder="Найди свой товар"
              />
            </div>
          </div>

          <div v-if="mnyams" class="absolute left-1/2 -translate-x-1/2">
            <NuxtLink to="/" aria-label="Главная страница">
              <slot name="logo" />
            </NuxtLink>
          </div>

          <div
            :class="[
              'z-0 hidden auto-cols-min grid-flow-col gap-x-3.5 lg:mt-2 lg:grid',
              b4p && 'grid',
              mnyams &&
                '!m-0 min-h-[62px] items-center rounded-[45px]  bg-gray-100 p-[5px]',
            ]"
          >
            <div
              v-if="fiory || b4p"
              :class="b4p && 'w-[400px] xl:w-[460px]'"
              class="flex items-center justify-end gap-2"
            >
              <div
                class="transition duration-500 ease-in-out"
                :class="!showSearch ? '!w-0 overflow-hidden' : '!w-[400px]'"
              >
                <AppHeaderSearch
                  v-model="searchActive"
                  class=""
                  :b4p="true"
                  placeholder="Найди свой товар"
                />
              </div>

              <Popper
                v-if="b4p"
                hover
                content="Найти"
                class="!hidden lg:!inline-block"
                @click="showSearch = !showSearch"
              >
                <Btn
                  secondary
                  class="!h-12 !w-12 rounded-full bg-[#282A2C] text-white shadow-none !ring-0"
                  :class="showSearch && '!bg-accent'"
                  aria-label="Найти"
                >
                  <Icon name="search" class="h-5 w-5" />
                </Btn>
              </Popper>
            </div>
            <Popper
              v-if="!hideAddress && !b4p && !kranch && !isb"
              hover
              content="Адрес доставки"
              class="!hidden lg:!inline-block"
              :class="[fedor && 'order-first']"
              data-test="header_adress"
            >
              <Btn
                :class="[
                  'hidden h-[52px] w-[169px] space-x-2 text-left lg:flex',
                  mnyams && 'rounded-full shadow-none',
                  'hidden h-[52px] w-[169px] space-x-2 text-left lg:flex',
                  fedor
                    ? '!hidden rounded-[20px] !bg-[#F8F8F8] md:!flex'
                    : 'shadow-container',
                ]"
                secondary
                class="bg-white"
                aria-label="Адрес доставки"
                @click="deliveryAddressDialog = true"
              >
                <Icon name="marker-pin" class="h-5 w-5 shrink-0" />
                <div
                  class="leading-base line-clamp-2 whitespace-normal text-xs"
                >
                  <span v-if="addressText" :title="addressFullText">
                    {{ addressText }}
                  </span>
                  <span v-else> Укажите адрес доставки </span>
                </div>
                <Icon name="expand-down" class="h-[14px] w-[14px] shrink-0" />
              </Btn>
            </Popper>
            <Popper
              v-if="!hideFavorite"
              hover
              content="Избранное"
              class="!hidden lg:!inline-block"
              :class="['order-last' && mnyams, 'order-first' && isb]"
              data-test="header_favorites"
            >
              <Btn
                to="/favorites/"
                secondary
                class="bg-white"
                :class="[
                  mnyams && 'rounded-full !text-[#838383] shadow-none',
                  fedor
                    ? 'hover:text-primary-hover h-[52px] w-[52px] rounded-[20px] !bg-[#F8F8F8]'
                    : 'shadow-container hover:text-primary h-[52px] w-[52px]',
                  favoriteIds.length > 0
                    ? 'text-primary hover:text-primary-hover ring-1 ring-current'
                    : fedor
                      ? 'text-[#474747]'
                      : isb
                        ? 'text-primary-500'
                        : 'text-blue-gray-500',
                ]"
                aria-label="Избранное"
              >
                <Icon name="favorite" class="h-5 w-5" />
                <div
                  v-if="favoriteIds.length > 0"
                  class="bg-primary absolute -right-3 -top-3 flex h-6 w-6 items-center justify-center rounded-[5.33px] text-white"
                >
                  <span class="text-xs leading-none">{{
                    favoriteIds.length
                  }}</span>
                </div>
              </Btn>
            </Popper>
            <Popper
              hover
              content="Корзина"
              :class="{ 'order-first': kranch || fedor }"
              class="!hidden lg:!inline-block"
              data-test="header_cart"
            >
              <Btn
                to="/cart/"
                secondary
                class="relative bg-white"
                :class="[
                  mnyams && 'rounded-full !text-[#838383] shadow-none',
                  b4p
                    ? '!h-12 !w-12 rounded-full !bg-[#282A2C] text-white shadow-none !ring-0'
                    : fedor
                      ? 'hover:text-primary-hover hidden h-[52px] min-w-[52px] rounded-[20px] !bg-[#F8F8F8] lg:flex '
                      : 'shadow-container hover:text-primary h-[52px] w-[52px]',
                  cartCount > 0
                    ? 'text-primary hover:text-primary-hover ring-1 ring-current'
                    : fedor
                      ? 'text-[#474747]'
                      : isb
                        ? 'text-primary-500'
                        : 'text-blue-gray-400 lg:text-blue-gray-500',
                ]"
                aria-label="Корзина"
              >
                <div v-if="fedor" class="flex items-center text-base">
                  <Icon name="shopping-cart" class="w-4.5 h-4.5" />
                </div>
                <div v-else-if="b4p" class="">
                  <Icon name="basket-b4p" class="w-4.5 h-4.5" />
                </div>
                <Icon v-else name="basket" class="h-5 w-5" />
                <div
                  v-if="cartCount > 0"
                  class="bg-primary absolute -right-3 -top-3 flex h-6 min-w-[24px] items-center justify-center rounded-[5.33px] px-1 text-white"
                  :class="
                    b4p &&
                    'absolute right-0 top-0 flex h-[14px] !w-auto !min-w-[14px] items-center justify-center rounded-full !bg-[#ED4747] p-1 !text-[10px] shadow-[0px_4px_4px_0px_#FF4E5533]'
                  "
                >
                  <div class="text-xs leading-none">
                    {{ cartCount }}
                  </div>
                </div>
              </Btn>
            </Popper>

            <Popper
              v-if="!hideCompare"
              hover
              content="Сравнение"
              class="!hidden lg:!inline-block"
              :class="{ 'order-first': isb }"
              data-test="header_compare"
            >
              <Btn
                to="/compare/"
                secondary
                class="bg-white"
                :class="[
                  mnyams && 'rounded-full !text-[#838383] shadow-none',
                  'shadow-container hover:text-primary h-[52px] w-[52px]',
                  compareIds.length > 0
                    ? 'text-primary hover:text-primary-hover ring-1 ring-current'
                    : isb
                      ? 'text-primary-500'
                      : 'text-blue-gray-500',
                ]"
                aria-label="Сравнение"
              >
                <Icon name="compare" class="h-5 w-5" />

                <div
                  v-if="compareIds.length > 0"
                  class="bg-primary absolute -right-3 -top-3 flex h-6 w-6 items-center justify-center rounded-[5.33px] text-white"
                >
                  <span class="text-xs leading-none">{{
                    compareIds.length
                  }}</span>
                </div>
              </Btn>
            </Popper>
            <Popper
              v-if="!hideNotifications"
              hover
              content="Уведомления"
              class="!hidden lg:!inline-block"
              data-test="header_notifications"
            >
              <Btn
                to="/notifications/"
                secondary
                class="bg-white"
                :class="[
                  mnyams && 'rounded-full !text-[#838383] shadow-none',
                  'shadow-container hover:text-primary h-[52px] w-[52px]',
                  notificationsCount > 0
                    ? 'text-primary hover:text-primary-hover ring-1 ring-current'
                    : isb
                      ? 'text-primary-500'
                      : 'text-blue-gray-500',
                ]"
                aria-label="Сравнение"
              >
                <Icon name="bell" class="h-5 w-5" />

                <div
                  v-if="notificationsCount > 0"
                  class="bg-primary absolute -right-3 -top-3 flex h-6 w-6 items-center justify-center rounded-[5.33px] text-white"
                >
                  <span class="text-xs leading-none">{{
                    notificationsCount
                  }}</span>
                </div>
              </Btn>
            </Popper>
            <Popper
              hover
              placement="bottom-end"
              class="header-profile z-20 !hidden lg:!inline-block"
              :class="{ 'order-last': mnyams }"
              data-test="header_profile"
            >
              <Btn
                secondary
                class=""
                :class="[
                  mnyams &&
                    '!bg-primary rounded-full text-white shadow-none hover:text-white',
                  b4p
                    ? '!h-12 !w-12 rounded-full !bg-[#282A2C] text-white shadow-none !ring-0'
                    : fedor
                      ? 'hover:text-primary-hover h-[52px] w-[52px] rounded-[20px] !bg-[#F8F8F8] text-[#474747]'
                      : 'shadow-container text-blue-gray-500 hover:text-primary h-[52px] w-[52px] bg-white',
                  isb && 'text-primary-500',
                ]"
                aria-label="Профиль"
                @click.prevent="goToAuthPage('/profile/')"
              >
                <Icon v-if="fedor" name="user-alt" class="w-4.5 h-4.5" />
                <Icon v-else-if="b4p" name="user-01" />
                <Icon v-else name="user" class="h-5 w-5" />
              </Btn>
              <template #content="{ close }">
                <div
                  v-if="loggedIn"
                  class="text-general bg-white !px-0 text-base"
                >
                  <Btn
                    class="hover:text-primary block px-4 py-2"
                    to="/profile/"
                    @click="close"
                  >
                    Личный кабинет
                  </Btn>
                  <!-- <Btn
                    class="block px-4 py-2 hover:text-primary"
                    @click="close"
                  >
                    Кабинет питомца
                  </Btn> -->
                  <Btn
                    to="/favorites/"
                    class="hover:text-primary block px-4 py-2"
                    @click="close"
                  >
                    Избранное
                  </Btn>
                  <Btn
                    to="/orders/"
                    class="hover:text-primary block px-4 py-2"
                    @click="close"
                  >
                    Статус заказа
                  </Btn>
                  <Btn
                    v-if="!hideNotifications"
                    to="/notifications/"
                    class="hover:text-primary flex items-center space-x-1 px-4 py-2"
                    @click="close"
                  >
                    <span>Уведомления</span>
                    <span
                      v-if="notificationsCount > 0"
                      class="bg-primary flex h-5 w-5 items-center justify-center rounded-[5.33px] text-xs leading-none text-white"
                      >{{ notificationsCount }}</span
                    >
                  </Btn>
                  <Btn
                    class="text-dark-red-700 hover:text-dark-red-900 border-blue-gray-100 mt-2 block w-full border-t p-4 pb-2 text-left"
                    @click="logout(route.fullPath)"
                  >
                    Выйти из профиля
                  </Btn>
                </div>
                <div v-else class="text-general bg-white text-base">
                  <Btn
                    class="hover:text-primary block px-4 py-2"
                    @click="
                      () => {
                        close()
                        login(route.fullPath)
                      }
                    "
                  >
                    Авторизоваться
                  </Btn>
                  <Btn
                    class="hover:text-primary block px-4 py-2"
                    @click="
                      () => {
                        close()
                        register('/profile/')
                      }
                    "
                  >
                    Зарегистрироваться
                  </Btn>
                </div>
              </template>
            </Popper>
            <div v-if="b4p" class="b4p flex items-center gap-2">
              <slot name="append" />
            </div>
          </div>
        </div>
      </div>

      <Dialog
        v-model="deliveryAddressDialog"
        fullscreen-on-mobile
        class="!p-0 lg:!max-w-[982px]"
      >
        <Btn
          class="text-secondary hover:text-blue-gray-700 shadow-container absolute right-3.5 top-3.5 z-10 flex h-[42px] w-[42px] items-center justify-center rounded-[10px] bg-white lg:rounded-xl"
          tabindex="-1"
          @click="deliveryAddressDialog = false"
        >
          <Icon name="close" class="h-6 w-6" />
        </Btn>
        <OrderDeliveryAddress
          :loading="isSetDeliveryAddressLoading"
          :init-delivery-type="profileStore.deliveryAddressType"
          :init-delivery-address="deliveryAddress"
          :init-pickup-address="pickupAddress"
          title="Укажите свой адрес"
          description="Чтобы видеть актуальную информацию по наличию товаров"
          @success="setDeliveryAddress"
        />
      </Dialog>
    </header>
  </div>
</template>

<script setup lang="ts">
import {
  DeliveryTypeEnum,
  type IOrderDeliveryAddressSuccessEmit,
} from '@/types'

// TODO типизация
const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  mailTo: {
    type: String,
    default: 'hotline@valta.ru',
  },
  phoneTo: {
    type: String,
    default: '8 (800) 600-28-04',
  },
  socialItems: Array,
  classTitle: {
    type: String,
    default: '',
  },
  classButton: {
    type: String,
    default: '',
  },
  isNavbarHidden: Boolean,
  hideNotifications: Boolean,
  hideFavorite: Boolean,
  hideAddress: Boolean,
  hideCompare: Boolean,
  wellness: Boolean,
  isb: Boolean,
  kranch: Boolean,
  dark: Boolean,
  fedor: Boolean,
  award: Boolean,
  b4p: Boolean,
  fiory: Boolean,
  mnyams: Boolean,
  craftia: Boolean,
  hideSubscribe: Boolean,
  lite: Boolean,
})
import { breakpointsTailwind } from '@vueuse/core'
const breakpoints = useBreakpoints(breakpointsTailwind)
const isDesktop = breakpoints.greaterOrEqual('lg')
const route = useRoute()
const cartStore = useCartStore()
const { loggedIn, login, register, logout } = useAuth()
const profileStore = useProfileStore()
const { NotificationsApi, ManagmentApi } = useOpenApi()

const showSearch = ref(false)
const searchActive = ref(false)
const deliveryAddressDialog = ref(false)
const isOpenedMenu = ref(false)
const isSetDeliveryAddressLoading = ref(false)
const { refresh: refreshGetDelivery } = await useLazyAsyncData(() =>
  profileStore.getDeliveryAddress(),
)

// TODO очистка куки profile
onMounted(() => {
  useCookie('profile', {
    sameSite: 'strict',
  }).value = undefined
})

const deliveryAddress = computed(() => {
  if (profileStore.deliveryAddressType !== DeliveryTypeEnum.PICK_UP) {
    return profileStore.lastOrderDeliveryAddressInfo?.lastOrderDeliveryAddress
      ?.address
  }
})

const pickupAddress = computed(() => {
  if (profileStore.deliveryAddressType === DeliveryTypeEnum.PICK_UP) {
    return profileStore.lastOrderDeliveryAddressInfo?.lastOrderPickupAddress
      ?.address
  }
})

const addressFullText = computed(() => {
  if (profileStore.lastOrderDeliveryAddressInfo) {
    if (profileStore.deliveryAddressType === DeliveryTypeEnum.PICK_UP) {
      return pickupAddress.value?.addressFull || ''
    } else {
      return deliveryAddress.value?.address || ''
    }
  }
})

const addressText = computed(() => {
  if (profileStore.lastOrderDeliveryAddressInfo) {
    if (profileStore.deliveryAddressType === DeliveryTypeEnum.PICK_UP) {
      return pickupAddress.value?.address
    } else {
      let _address = [
        deliveryAddress.value?.street || deliveryAddress.value?.settlement,
        deliveryAddress.value?.house || deliveryAddress.value?.stead,
      ]
        .filter(Boolean)
        .join(', ')
      if (deliveryAddress.value?.block) {
        _address += ` к${deliveryAddress.value?.block}`
      }
      return _address
    }
  }
})

const cartCount = computed(() => cartStore.cart?.totalProductCount ?? 0)

const notificationsCount = computed(() => profileStore.notificationsCount)

const favoriteIds = computed(() => profileStore.favoriteIds)

const compareIds = computed(() => profileStore.compareIds)

const { data: notificationsCountResponse, refresh: refreshNotificationsCount } =
  useLazyAsyncData<any>(
    '/notifications/count/',
    () => {
      if (!props.hideNotifications && loggedIn.value) {
        return NotificationsApi.notificationsCountRetrieve()
      }
      return Promise.resolve(null)
    },
    { server: false },
  )

watch(notificationsCountResponse, (val) => {
  if (val) {
    profileStore.notificationsCount = val?.count || 0
  }
})

const {
  data: favorites,
  pending: favoritesPending,
  refresh: refreshFavorites,
} = useLazyAsyncData(
  '/managment/featured/count/',
  () => ManagmentApi.managmentFeaturedList(),
  { server: false },
)

watch(favorites, (val) => {
  if (Array.isArray(val)) {
    profileStore.favoriteItems = val
  } else {
    profileStore.favoriteItems = []
  }
})

watchEffect(() => {
  profileStore.favoritesPending = favoritesPending.value
})

const {
  data: compares,
  pending: comparesPending,
  refresh: refreshCompares,
} = useLazyAsyncData(
  '/managment/compare/count/',
  () => {
    if (!props.hideCompare) {
      return ManagmentApi.managmentCompareList()
    }
    return Promise.resolve(null)
  },
  { server: false },
)

watch(compares, (val) => {
  profileStore.compareItems = val || []
})

watchEffect(() => {
  profileStore.comparesPending = comparesPending.value
})

watch(
  () => loggedIn.value,
  (val, oldVal) => {
    // login
    if (val && !oldVal) {
      refreshFavorites()
      refreshCompares()
      refreshNotificationsCount()
      cartStore.load()
      nextTick(refreshGetDelivery)
    }
    // logout
    if (!val) {
      nextTick(() => {
        profileStore.lastOrderDeliveryAddressInfo = null
      })
    }
  },
)

watch(
  isOpenedMenu,
  (val) => {
    if (process.client) {
      if (val) {
        document.documentElement.classList.add('overflow-hidden')
      } else {
        document.documentElement.classList.remove('overflow-hidden')
      }
    }
  },
  { immediate: true },
)

watch(
  () => profileStore.deliveryAddressRegion,
  (val, oldVal) => {
    if (val && oldVal && val !== oldVal) {
      cartStore.load()
    }
  },
)

watch(
  () => profileStore.deliveryAddressSelectDialogOpener,
  (val) => {
    if (val) {
      deliveryAddressDialog.value = true
    }
  },
)

watch(deliveryAddressDialog, (val) => {
  if (!val) {
    profileStore.deliveryAddressSelectDialogOpener = false
  }
})

async function setDeliveryAddress(data: IOrderDeliveryAddressSuccessEmit) {
  try {
    isSetDeliveryAddressLoading.value = true

    await profileStore.setDeliveryAddress(data)

    deliveryAddressDialog.value = false
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  } finally {
    isSetDeliveryAddressLoading.value = false
  }
}

async function goToAuthPage(path: string) {
  if (loggedIn.value) {
    await navigateTo(path)
  } else {
    await login(path)
  }
  closeMenu()
}

function closeMenu() {
  isOpenedMenu.value = false
}
</script>

<style>
.navbar-on-scroll-enter-active {
  animation: show-on-scroll 0.2s cubic-bezier(0, 0, 0.2, 1);
}

.navbar-on-scroll-leave-active {
  animation: show-on-scroll 0.2s cubic-bezier(0.4, 0, 1, 1) reverse;
}

@keyframes show-on-scroll {
  0% {
    opacity: 0;
    height: 0;
  }
  100% {
    opacity: 1;
    height: 2rem;
  }
}
</style>
